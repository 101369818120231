// Custom.scss
// Option B: Include parts of Bootstrap


// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$primary: #63a4f1;
$secondary: #71c4fb;
$tertiary: #476de5;
$light: white;

$white: #FFFFFF;
$dark: #000000;

$enable-negative-margins: true;

$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 6,
        7: $spacer * 8
) !default;



// 3. Include remainder of required Bootstrap stylesheets
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";


$custom-colors: (
        "tertiary": $tertiary
);
// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);



// 4. Include any optional Bootstrap components as you like
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";

// 5. Add additional custom code here


@import "~bootstrap/scss/bootstrap";