body {
  @extend .poppins;
}

.poppins {
  font-family: 'Poppins', sans-serif;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}



// -- Text sizes --
html {
  font-size: 14px;
  @include media-breakpoint-up("md") {
    font-size: 16px;
  }
  @include media-breakpoint-up("lg") {
    font-size: 18px;
  }
}

.mainTitle {
  font-size: 30px;
  line-height: 1.5;
  //font-weight: 500;

  @include media-breakpoint-up("sm") {
    font-size: 35px;
  }
  @include media-breakpoint-up("lg") {
    font-size: 42px;
  }
  @include media-breakpoint-up("xxl") {
    font-size: 50px;
  }
}

.title {
  font-size: 26px;
  line-height: 1.3;
  font-weight: bold;

  @include media-breakpoint-up("lg") {
    font-size: 30px;
  }
}

//.subtitle {
//  font-size: 22px;
//  line-height: 1.3;
//
//  @include media-breakpoint-up("lg") {
//    font-size: 26px;
//  }
//}

section {
  width: 100%;
  padding: 10vh 5.5%;

  @include media-breakpoint-up("md") {
    padding: 3% 5.5%;
  }
}


.btn {
  border: 0.1px $white solid;
  font-weight: bold;
}
.btn-primary, .btn-primary:hover {
  color: $white;
}
.rounded {
  border-radius: 1rem !important;
}

.link:hover{
  color: $primary;
}


.thumbnail {
  -webkit-box-shadow: 10px 10px 35px 3px rgba(0,0,0,0.5); 
  box-shadow: 10px 10px 35px 3px rgba(0,0,0,0.5);
}




@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}
//rgba(var(#{hexToRGB($secondary)}), 0)

$secondary-65: rgba($secondary, 0.65);
.bg-secondary-65 {
  background-color: $secondary-65;
}

.bg-g-secondary {
  background: linear-gradient(0deg, $secondary-65 75%, rgba(0,0,0,0) 100%);
}
.bg-g-secondary-reversed {
  background: linear-gradient(0deg, rgba(0,0,0,0) 10%, $secondary-65 100%);
}
.bg-g-secondary-responsive {
  background: linear-gradient(0deg, rgba(0,0,0,0) 10%, $secondary-65 100%);
  @include media-breakpoint-up("md") {
    background: linear-gradient(0deg, $secondary-65 75%, rgba(0,0,0,0) 100%);
  }
}
.bg-g-light-secondary {
  background: linear-gradient(0deg, $secondary 50%, $light 100%);
}



.legalText  {
  line-height: 1.15;

  h1 {
    color: $primary;
    text-align: center;
    font-weight: bold;
    margin-bottom: 70px;
  }
  h2 {
    margin-top: 50px;
    margin-bottom: 15px;
    font-weight: bold;
  }
  a {
		color: $primary;
		text-decoration: none;
  }
  li {
    margin-bottom: 10px;
  }
  ul, ol {
    margin-bottom: 20px;
  }
  table {
    margin: 30px 0;
    border-collapse: collapse;
    width: 100%;
  }
  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: $primary;
    font-weight: bold;
    color: white;
  }
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  tr:nth-child(even){background-color: #f2f2f2;}
}

.StepperContainer-0-2-1, .StepperContainer-3-2-1 {
  padding: unset !important;
}

#RFS-Label {
  margin-top: 6px;
}
